import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";

import { Box, makeStyles } from "@material-ui/core";

import { getRefreshNeeded } from "src/store/dashboard/selector";
import {
  fetchLivePlanProjection,
  fetchPlanProjection,
  implementPlan,
  setBuildStep,
  setReviewSection,
} from "src/store/planBuild/actions";
import {
  PLAN_BUILD_STEPS,
  ReviewSections,
} from "src/store/planBuild/constants";
import {
  getCurrentPlan,
  getLoadingProjection,
  getMinimalProjection,
  getReviewSection,
  isCurrentPlanImplemented,
} from "src/store/planBuild/selector";
import { getIsSubscribed } from "src/store/system/selector";
import { PlanViewComponent } from "src/interfaces";

import { AppLoader } from "src/components";

import Home from "./ReviewViews/Home";
import Score from "./ReviewViews/Score";
import GoalSummary from "./ReviewViews/GoalSummary";
import AssetsInvestments from "./ReviewViews/AssetsInvestments";
import Debts from "./ReviewViews/Debts";
import NetWealth from "./ReviewViews/NetWealth";
import PayCheckAllocation from "./ReviewViews/PayCheckAllocation";
import { disableSidebar } from "src/store/dashboard/actions";
import Button from "src/components/Button";
import ReviewSidebar from "../../Components/ReviewSidebar";
import EditDialog from "./EditDialog";
import { getHeaderClicked } from "src/store/dashboard/selector";
import PingEffect from "src/components/PingAnimation";

const ReviewPlan: PlanViewComponent = ({ render }) => {
  const dispatch = useDispatch();
  const styles = useStyles();
  const currentPlan = useSelector(getCurrentPlan);
  const subscribed = useSelector(getIsSubscribed);
  const loading = useSelector(getLoadingProjection);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const reviewIndex = useSelector(getReviewSection);
  const headerClickCount = useSelector(getHeaderClicked);
  const currentPlanIsImplemented = useSelector(isCurrentPlanImplemented);
  const refreshNeeded = useSelector(getRefreshNeeded);
  const minimalProjection = useSelector(getMinimalProjection);
  const [triggerCount, setTriggerCount] = useState(headerClickCount);

  useEffect(() => {
    if (currentPlanIsImplemented && reviewIndex === ReviewSections.HOME) {
      dispatch(setReviewSection(ReviewSections.SCORE));
    }
  }, [currentPlanIsImplemented, reviewIndex]);

  const toggleEditDialog = () => setShowEditDialog(!showEditDialog);

  useEffect(() => {
    if (!currentPlanIsImplemented) {
      dispatch(fetchPlanProjection());
    } else if (refreshNeeded.liveProjection || !minimalProjection) {
      dispatch(fetchLivePlanProjection(true));
    }
  }, []);

  // if triggerAnimation is updated, run the animation
  useEffect(() => {
    if (headerClickCount !== triggerCount) {
      setTriggerCount(headerClickCount);
    }
  }, [headerClickCount]);

  const onNext = () => {
    if (subscribed) {
      if (currentPlan && currentPlan.id) {
        dispatch(implementPlan(currentPlan.id));
      }
      dispatch(push("/home"));
      dispatch(setBuildStep(PLAN_BUILD_STEPS.NAME));
    } else {
      dispatch(setBuildStep(PLAN_BUILD_STEPS.IMPLEMENT));
    }
  };

  const changeSectionTo = (nextIndex: number) => {
    dispatch(disableSidebar());
    dispatch(setReviewSection(nextIndex));
  };

  const sections = {
    [ReviewSections.HOME]: <Home />,
    [ReviewSections.SCORE]: <Score />,
    [ReviewSections.GOALSUMMARY]: <GoalSummary />,
    [ReviewSections.ASSETSINVESTMENTS]: <AssetsInvestments />,
    [ReviewSections.DEBTS]: <Debts />,
    [ReviewSections.NETWEALTH]: <NetWealth />,
    [ReviewSections.PAYCHECKALLOCATION]: <PayCheckAllocation />,
  };

  return render({
    component: (
      <Box style={{ display: "flex" }}>
        <Box>
          <ReviewSidebar
            handleEdit={toggleEditDialog}
            handleClick={changeSectionTo}
          />
        </Box>

        <Box className={styles.mainContainer}>
          {loading && (
            <Box className={styles.loaderContainer}>
              <AppLoader />
            </Box>
          )}
          {sections[reviewIndex]}

          {/* <Box className={styles.transitionContainer}>
            {reviewIndex > ReviewSections.HOME && (
              <Box
                className={styles.transition}
                onClick={() => {
                  handleTransition();
                }}
              >
                <ArrowBackIosIcon />
              </Box>
            )}

            {reviewIndex < ReviewSections.PAYCHECKALLOCATION && (
              <Box
                className={styles.transition}
                onClick={() => {
                  handleTransition(true);
                }}
              >
                <ArrowForwardIosIcon />
              </Box>
            )}
          </Box> */}
        </Box>
        {!currentPlanIsImplemented && (
          <Box className={styles.footer}>
            <Box className={styles.implement}>
              <PingEffect trigger={headerClickCount !== triggerCount} />
              <Button color="primary" className="h-10" onClick={onNext}>
                Implement This Plan
              </Button>
            </Box>
          </Box>
        )}
        <EditDialog open={showEditDialog} onClose={toggleEditDialog} />
      </Box>
    ),
    hideSidebars: true,
    onNext,
    // displayReviewSidebar: true,
  });
};

export default ReviewPlan;

const useStyles = makeStyles({
  mainContainer: {
    height: "calc(100vh - 170px)",
    width: "100%",
    paddingLeft: "20px",
    // overflowY: "hidden"
  },
  transitionContainer: {
    margin: "20px 0",
    display: "flex",
    justifyContent: "center",
  },
  transition: {
    width: "50px",
    display: "flex",
    justifyContent: "center",
    "&:hover": {
      cursor: "pointer",
    },
  },
  loaderContainer: {
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 555,
    background: "rgba(255, 255, 255, .8)",
  },
  footer: {
    position: "absolute",
    backgroundColor: "white",
    bottom: "0px",
    width: "calc(100% + 60px)",
    height: "80px",
    marginLeft: "-100px",
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
  },
  implement: {
    display: "flex",
    position: "relative",
    marginRight: "calc(50vw - 200px)",
  },
});
