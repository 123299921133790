import React from "react";

interface FlyoutInfoProps {
  infoType:
  | "human-capital"
  | "fitbux-score"
  | "children"
  | "add-manual-account"
  | "income-details"
  | string;
  componentType:
  | "header"
  | "body"
  | "contentAction"
  | "submitAction"
  | "cancelAction";
}

function FlyoutInfo({ infoType, componentType }: FlyoutInfoProps) {
  let header = "";
  let body: React.ReactNode = "";
  let contentAction: string | undefined;
  let submitAction: string | undefined;
  let cancelAction: string | undefined;

  switch (infoType) {
    case "human-capital":
      header = "Human Capital";
      body = (
        <>
          <p>
            <iframe
              width="100%"
              height="200"
              src="https://www.youtube.com/embed/iWgkM8Wqx_M"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </p>
          <p>
            Human capital is a combination of our future income and the risk to
            that income. It's important because it dictates everything we do
            financially. This includes how we pay down student loans, whether to
            rent or buy a house, how much insurance we need, how to allocate
            investments, among other decisions.
          </p>
          <p>
            Think about a technology business. Their largest assets are
            intangibles (i.e., their code, their technology, copyrights,
            patents, etc.). In the long run, businesses realize the value of
            these things in the form of revenue.
          </p>
          <p>
            Human capital is your intangible asset. However, instead of
            technology, copyrights, and patents, you have education, skills, and
            behavioral traits that have been developed over time. Also, instead
            of monetizing your human capital over time via revenue, you do so in
            the form of income; in other words, you recognize the value of your
            human capital over time in the form of income.
          </p>
          <p>
            Analyzing human capital is one way you can determine trade-offs
            between choices. For example,{" "}
            <a
              href="https://www.fitbux.com/articles/physical-therapist-salary/"
              target="_blank"
            >
              should you get an advanced degree such as your DPT or not
            </a>
            . You can also see what professions have the best financial outlook
            for yourself based on the expected increase in human capital value.
          </p>
        </>
      );
      submitAction = "Done";
      break;
    case "fitbux-score":
      header = "FitBUX Score";
      body = (
        <>
          <p>
            The FitBUX Score is a revolutionary tool in the realm of financial
            planning, designed to make the complex process of managing and
            comparing financial options straightforward and user-friendly. In
            the traditional financial landscape, understanding and comparing
            different financial plans can be a daunting task, often mired in
            pages of dense data and confusing metrics. The FitBUX Score
            simplifies this by distilling your financial information, including
            assets, debts, human capital, and behavioral patterns, into one
            comprehensive, easy-to-understand number. This innovative approach
            allows you to effortlessly compare different financial scenarios and
            make informed decisions without getting lost in the details.
          </p>
          <p>
            With the FitBUX Score, financial planning becomes a breeze. Whether
            you're evaluating loan repayment options, considering investment
            strategies, or planning for future financial goals, this tool
            provides a clear, concise benchmark for comparison. It's like having
            a financial GPS that guides you through the myriad of choices,
            highlighting the impact of each decision on your overall financial
            health. The FitBUX Score empowers you to navigate the financial
            landscape with confidence, providing a straightforward way to assess
            and compare various financial paths. Embrace a smarter, simpler way
            to plan your finances with the FitBUX Score, and take control of
            your financial future with ease and clarity.
          </p>
        </>
      );
      submitAction = "Done";
      break;
    case "children":
      header = "Children";
      body = (
        <>
          <p>
            Children add cost and uncertainty to your financial plan. Therefore,
            you have less cash flow and increased risk. Thus, children reduce
            your FitBUX Score.
          </p>
          <p>
            You can potentially decrease the risk by having the appropriate
            types of insurances (life and disability) as well as manipulating
            your debt payments to increase your budget flexibility. Be sure to
            ask us about these topics when you schedule a call.
          </p>
        </>
      );
      submitAction = "Done";
      break;
    case "income-details":
      header = "Your Income & Deductions";
      body = (
        <>
          <p>To track your financial plan correctly, you need to know how your overall income is allocated using percentages.
          </p>
          <p>Your primary goal should be getting 50% of you Total Income allocated to money for your future self (in order to grow your assets and/or pay off debts)!
          </p>
          <p>Total Income should be broken out into salary and any other sources of income, such as bonuses or commission. This is important because salary and commissions have different risks which are reflected in your FitBUX Score and our recommendations. Also, income such as stipends are taxed differently. Total Income is the same as Gross Income.</p>
          <p>Deductions should be any item taken directly out of your paycheck before the money hits your bank account.</p>
        </>
      );
      submitAction = "Back";
      break;
    default:
      header = "Default Header";
      body = "Default Information";
  }

  if (componentType === "header") {
    return header;
  } else if (componentType === "body") {
    return body;
  } else if (componentType === "contentAction") {
    return contentAction || "";
  } else if (componentType === "submitAction") {
    return submitAction || "";
  } else if (componentType === "cancelAction") {
    return cancelAction || "";
  } else {
    return null;
  }
}

export default FlyoutInfo;
